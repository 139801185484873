import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/Dashboard.css';
import '../../css/dashboard/PropertyActions.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import UserContext from "../context/UserContext";

function PropertyActions() {
  const {user,setUser} = useContext(UserContext);
  const [data1,setData1] = useState(null);
  const [totals,setTotals] = useState({total :-1, option : -1, sold : -1});
  let data = {
    className: "property-actions",
    title: "Property Actions",
    headings: ["Date", "Property", "Client", "Sales Rep","Status"],
    rows: []
  };
  useEffect(() => {


    apiConst.getUser(user.id).then(user => {

      setTotals({
        total : user.totals.sold + user.totals.option,
        option : user.totals.option,
        sold : user.totals.sold,
      })

      if(user.role.id === appConst.role.sales_manager)
      {
        user.manager.actions.forEach(action => {
          data.rows.push({
            date : action.date,
            property : {id : action.property_id,name: action.property_code},
            client: {id : action.client_id,name:action.client_name},
            salesRep: {id : action.user_id,name:action.user_name},
            status : appConst.getStatusTitle(action.status_id)
          })
        })
        setData1(data);
      }
      else
      {
        apiConst.getPropertyActions().then(actions => {
          actions.forEach(action => {
            data.rows.push({
              date : action.date,
              property : {id : action.property_id,name: action.property_code},
              client: {id : action.client_id,name:action.client_name},
              salesRep: {id : action.user_id,name:action.user_name},
              status : appConst.getStatusTitle(action.status_id)
            });
          })

          setData1(data);
        })
      }

    })




  },[])



  

  return (

    <section className="primary dashboard property-actions">
      <DashboardHeader slug="property-actions" />
  
        <div className="container">
        <div className="row">
          
          <div className="col-2">

            <NumberBox className="number-box" number={totals.total} text="Total" />
            <NumberBox className="number-box" number={totals.sold} text="Sold" />
            <NumberBox className="number-box" number={totals.option} text="Option" />
            
          </div>

          

          <div className="col-10">

            <div className="row">
              <div className="col-12">
              <DashboardTable data={data1} height={"h-80"}/>
              </div>
            </div>

            
            
         </div>
          
        </div>
      </div>
    </section>

  );
  
}

export default PropertyActions;