import React, {useContext, useEffect, useState} from 'react';
import "../css/PropertyView.css"
import FavButton from "./FavButton";
import {withRouter, useParams, Link, useLocation} from "react-router-dom";
import {apiConst} from "../constant/apiConst";
import {appUtil} from "../constant/appUtil";
import AlertContext from "./context/AlertContext";
import {propertyConst} from "../constant/propertyConst";
import UserContext from "./context/UserContext";
import ClientContext from "./context/ClientContext";
import Confirm from "./Confirm";
import {appConst} from "../constant/appConst";
import FavContext from "./context/FavContext";
import ConfirmContext from "./context/ConfirmContext";
import AppDataContext from "./context/AppDataContext";
import LightBox from "./LightBox";
import LightBoxContext from "./context/LightBoxContext";
import Viewer360 from "./Viewer360";
import {appSettings} from "../constant/appConst";
import {Zoom,FreeMode, Navigation, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import FullScreenButton from "./FullScreenButton";
import FavCompareContext from "./context/FavCompareContext";

function PropertyView(props) {

    const {appData, setAppData} = useContext(AppDataContext);
    const {user,setUser} = useContext(UserContext);
    const {client,setClient} = useContext(ClientContext);
    const {alert,setAlert} = useContext(AlertContext);
    const {confirm,setConfirm} = useContext(ConfirmContext);
    const {lightBox,setLightBox} = useContext(LightBoxContext);
    const {favs,setFavs} = useContext(FavContext);

    const [is360Active,set360Active] = useState(false);
    const [property,setProperty] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [favId,setFavId] = useState(0);
    const [isSoldOrOption,setIsSoldOrOption] = useState("");
    const [isFavWaiting,setIsFavWaiting] = useState(false);
    const [activeContent,setActiveContent] = useState("images");
    const [clickParam,setClickParam] = useState(-1);
    const [isFullScreen,setIsFullScreen] = useState(false);
    const params = useParams()
    const {favCompareList,setFavCompareList} = useContext(FavCompareContext);
    useEffect(()=>{

        if(user && client)
        {
            apiConst.findPropertyView(user.id,client.id,params.property_id).then(result => {

                if(result.response)
                {
                    let propertyView = result.propertyView;
                    let view_count = parseInt(propertyView.view_count);
                    apiConst.propertyViewUpdate(propertyView.id,++view_count).then(result => {

                    })
                }
                else
                {
                    apiConst.propertyViewCreate(user.id,client.id,params.property_id).then(result => {

                    });
                }
            })
        }

    },[])
    useEffect(() => {

        apiConst.getProperty(params.property_id).then(result => {
            setProperty(result);
            if(result.status_id === appConst.status.sold)
                setIsSoldOrOption("Sold");
            else if(result.status_id === appConst.status.reserved)
                setIsSoldOrOption("Reserved");
            else if(result.status_id === appConst.status.not_released)
                setIsSoldOrOption("Deposit");
            else
                setIsSoldOrOption("");

        });



    },[params]);



    useEffect(() => {
        if(favs && property)
        {

            if(favs.length)
            {
                let id = 0
                favs.forEach((fav) => {
                    if(property.id === fav.property.id)
                        id = fav.id
                })
                setFavId(id);
            }
            else
            {
                setFavId(0);
            }

        }
    },[favs,property,favId])


    function onBottomButtonClick(activeContent){
        if(activeContent !== "images" || activeContent !== "floorplan" ) setThumbsSwiper(null);
        setActiveContent(activeContent)
    }




    function checkLogin()
    {
        if(!user || !client)
        {
            setAlert("There is no active user or client");
            return false;
        }

        return true
    }

    function checkBroker(){
        if(user && client) {
            if(user.role_id === appConst.role.broker){
                setAlert("You can't do any property action due to broker role")
                return false;
            }
        }
        return true
    }
    function onFavClick(){

        if(!checkLogin()) return;

        setIsFavWaiting(true);
        if(favId)
        {
            apiConst.favDelete(favId).then(result => {
                if(result.response)
                {
                    setFavId(0)
                    setIsFavWaiting(false);

                    let newFavs = [...favs];
                    if(newFavs.length)
                    {
                        newFavs.forEach((fav,i) => {
                            if(fav.id === favId) newFavs.splice(i,1);
                        });
                        setFavs(newFavs);
                        sessionStorage.setItem("favs",JSON.stringify(newFavs));

                    }
                    setAlert("Property deleted from favorite list successfully");
                }
                else {
                    setAlert("Server error occured try again later");
                }
            })
        }
        else
        {
            apiConst.favCreate(client.id,property.id).then(result => {
                if(result.response)
                {
                    setFavId(result.propertyFav.id);
                    setIsFavWaiting(false);

                    let newFavs = [...favs];
                    newFavs.push(result.propertyFav);
                    setFavs(newFavs);
                    sessionStorage.setItem("favs",JSON.stringify(newFavs));
                    setAlert("Property added to favorite list successfully");
                }
                else {
                    setAlert("Server error occured try again later");
                }
            })
        }
    }


    function getFields(status)
    {
        let fields = {
            user_id : user.id,
            client_id : client.id,
            presentation_id : sessionStorage.getItem("presentation_id"),
            property_id : property.id,
            status_id : status
        }
        return fields;
    }

    function onBuyClick(){
        if(!checkLogin()) return;
        if(!checkBroker()) return;
        setConfirm({show:true,text:"You want to buy this property",onConfirm:() => { onConfirm(appConst.status.sold)} })

    }

    function onOptionClick(){
        if(!checkLogin()) return;
        if(!checkBroker()) return;
        setConfirm({show:true,text:"You want to reserve this property",onConfirm:() => { onConfirm(appConst.status.reserved)} })
    }

    function onCancelClick(){
        if(!checkLogin()) return;
        if(!checkBroker()) return;
        setConfirm({show:true,text:"You want to cancel buy or reserve process",onConfirm:() => { onConfirm(appConst.status.available)} })
    }

    function onConfirm(status){

        let fields = getFields(status)

        apiConst.propertyUpdate(property.id,fields).then(result => {

            if(result.response)
            {
                let newAppData = {...appData}
                newAppData.properties.forEach(function(currentProperty,i){
                    if(property.id === currentProperty.id)
                    {
                        currentProperty.status_id = status;
                        if(status === appConst.status.available)
                            currentProperty.status.label = "Available";
                        if(status === appConst.status.sold)
                            currentProperty.status.label = "Sold";
                        if(status === appConst.status.reserved)
                            currentProperty.status.label = "Reserved";
                        if(status === appConst.status.not_released)
                            currentProperty.status.label = "Deposit";
                    }
                });
                newAppData.filteredProperties.forEach(function(currentProperty,i){
                    if(property.id === currentProperty.id)
                    {
                        currentProperty.status_id = status;
                        if(status === appConst.status.available)
                            currentProperty.status.label = "Available";
                        if(status === appConst.status.sold)
                            currentProperty.status.label = "Sold";
                        if(status === appConst.status.reserved)
                            currentProperty.status.label = "Reserved";
                        if(status === appConst.status.not_released)
                            currentProperty.status.label = "Deposit";
                    }
                });

                newAppData.floorStatuses = newAppData.getFreshFloorStatuses();
                newAppData.buildingStatuses = newAppData.getFreshBuildingStatuses();
                window.updateBuildingStatuses(newAppData.buildingStatuses);
                setAppData(newAppData);

                let message;
                switch (status)
                {
                    case appConst.status.available:
                        message = "Successfully canceled";
                        break;
                    case appConst.status.sold:
                        message = "Successfully bought";
                        break;
                    case appConst.status.reserved:
                        message = "Successfully reserved";
                        break;
                }

                setTimeout(()=> {
                    if(status === appConst.status.available)
                    {
                        apiConst.propertyActionDelete(fields.property_id).then(result => {
                            if (result.response) {
                                console.log("propertyActions silindi")
                            }
                        })
                    }
                    else {
                        apiConst.propertyActionCreate(fields).then(result => {

                            if (result.response) {
                                console.log("propertyActions oluştu")
                            }
                        })
                    }
                },1000)

                setAlert(message);
                setIsSoldOrOption(status);



            }
        })



    }

    function onClickFullScreen(){
        setIsFullScreen(!isFullScreen)
    }

    useEffect(()=> {
        if(property)
        {
            console.log("prop-room",propertyConst.floorplan_slider[property.room_type_folder])
        }
    },[property])

  return (

    <section className = {isFullScreen ? "primary property-view view-fullscreen" : "primary property-view"}>
        <div className="property-view-container">
            <button className="close-button colored" onClick={() => props.history.goBack()}>
                <div></div>
                <div></div>
            </button>

            {property && <div className="main-container">

                <div className="row h-100 no-gutters">
                    <div className="col-9" onContextMenu={(e)=> e.preventDefault()}>
                        {isSoldOrOption && <div className="status-label">{isSoldOrOption}</div>}
                        {activeContent === "information" &&
                            <div className={"h-100"}>
                                <Swiper
                                    zoom={true}
                                    spaceBetween={0}
                                    slidesPerView={1}

                                    onSwiper={(swiper) => console.log(swiper)}
                                    navigation={true}
                                    modules={[Zoom,FreeMode, Navigation]}
                                    className="property-swiper"
                                >

                                        return (<SwiperSlide >
                                            <div className="swiper-zoom-container">
                                                <img src={require("../img/info_slider/"+property.code+".jpg")} />
                                            </div></SwiperSlide>)
                                    )

                                </Swiper>

                            </div>}
                        {activeContent === "images" &&
                            <div className={"h-100"}>
                            <Swiper
                                zoom={true}
                                spaceBetween={0}
                                slidesPerView={1}
                                thumbs={{ swiper: thumbsSwiper }}
                                onSwiper={(swiper) => console.log(swiper)}
                                navigation={true}
                                modules={[Zoom,FreeMode, Navigation, Thumbs]}
                                className="property-swiper"
                            >
                                {propertyConst.slider[property.room_type_folder].map ((item,i) => {
                                    return (<SwiperSlide key={i}>
                                        <div className="swiper-zoom-container">
                                            <img src={item.source} />
                                        </div></SwiperSlide>)
                                })}

                            </Swiper>
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                loop={false}
                                spaceBetween={10}
                                breakpoints={{
                                    768 : {
                                        slidesPerView:4,
                                    },
                                    1250: {
                                        slidesPerView: 6,
                                    },
                                    1441: {
                                        slidesPerView: 8,
                                    },
                                }}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="property-thumb-swiper"
                            >
                                {propertyConst.slider[property.room_type_folder].map ((item,i) => {
                                    return (<SwiperSlide key={i}><img src={item.source} /></SwiperSlide>)
                                })}
                            </Swiper>
                        </div>
                        }
                        {activeContent === "floorplan" &&
                            <div className={"h-100"}>
                                <Swiper
                                    zoom={true}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    navigation={true}
                                    modules={[Zoom,FreeMode, Navigation, Thumbs]}
                                    className="property-swiper"
                                >
                                    {propertyConst.floorplan_slider[property.room_type_folder].map ((item,i) => {
                                        return (<SwiperSlide key={i}>
                                            <div className="swiper-zoom-container">
                                                <img src={item.source} />
                                            </div></SwiperSlide>)
                                    })}

                                </Swiper>
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    loop={false}
                                    spaceBetween={10}
                                    breakpoints={{
                                        768 : {
                                            slidesPerView:4,
                                        },
                                        1250: {
                                            slidesPerView: 6,
                                        },
                                        1441: {
                                            slidesPerView: 8,
                                        },
                                    }}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="property-thumb-swiper"
                                >
                                    {propertyConst.floorplan_slider[property.room_type_folder].map ((item,i) => {
                                        return (<SwiperSlide key={i}><img src={item.source} /></SwiperSlide>)
                                    })}
                                </Swiper>
                            </div>
                        }
                        {activeContent === "360" && <Viewer360 link={propertyConst.vr360[property.room_type_folder]} />}

                        {activeContent !== "360" && <FullScreenButton onClick={onClickFullScreen} /> }

                    </div>
                    <div className="col-3">
                        <div className="property-details-container">

                            <div className="property-details">

                                    <h1 className="property-title">{property.number} <br/> <span>{property.room_type.label}</span><span> / {property.net_area} {appSettings.areaUnit} </span></h1>
                                    {appSettings.showPrices && <p className="property-price">{appUtil.formatPrice(property.price)}</p>}
                                    {<p className="delivery-date"><strong>Delivery Date:</strong> {appUtil.convertStringToDate(property.delivery_date)}</p>}
                                    <div className={"fav-share-container"}>
                                        <FavButton isFav={favId} isWaiting={isFavWaiting} onClick={onFavClick}  />
                                        { !isSoldOrOption && <button className="action-button default-button " onClick={onOptionClick}>RESERVE</button>}
                                        { isSoldOrOption && <button className="action-button default-button " onClick={onCancelClick}>CANCEL</button>}
                                    </div>



                            </div>
                            <div className="bottom-buttons">
                                <button className={activeContent==="information" ? "btn-information active": "btn-information"} onClick={() => onBottomButtonClick("information")}>INFO</button>
                                <button className={activeContent==="images" ? "btn-images active": "btn-images"} onClick={() => onBottomButtonClick("images")}>IMAGES</button>
                                <button className={activeContent==="floorplan" ? "btn-floorplan active": "btn-floorplan"} onClick={() => onBottomButtonClick("floorplan")}>FLOOR PLAN</button>
                                {!appSettings.is360External && <button className={activeContent==="360" ? "btn-360 active": "btn-360"} onClick={() => onBottomButtonClick("360")}>360 TOUR</button>}
                                {appSettings.is360External && <Link className="btn-360" to={{pathname : propertyConst.vr360[property.room_type_folder]}} target={"_blank"}>360 TOUR</Link>}

                                <button className={activeContent==="finance" ? "btn-finance active d-none": "btn-finance d-none"} onClick={() => onBottomButtonClick("finance")}>FINANCE</button>

                            </div>

                        </div>


                    </div>
                </div>

            </div>}
        </div>




    </section>

  );
}

export default withRouter(PropertyView);