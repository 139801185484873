export const propertyConst = {
    slider : {
        "a_11" : [
            {source : require("../img/property_slider/a_11/i1.jpg")},
            {source : require("../img/property_slider/a_11/i2.jpg")},
            {source : require("../img/property_slider/a_11/i3.jpg")},
            {source : require("../img/property_slider/a_11/i4.jpg")},
            {source : require("../img/property_slider/a_11/i5.jpg")},
            {source : require("../img/property_slider/a_11/i6.jpg")},
            {source : require("../img/property_slider/a_11/i7.jpg")},
        ],
        "a_21" : [
            {source : require("../img/property_slider/a_21/i1.jpg")},
            {source : require("../img/property_slider/a_21/i2.jpg")},
            {source : require("../img/property_slider/a_21/i3.jpg")},
            {source : require("../img/property_slider/a_21/i4.jpg")},
            {source : require("../img/property_slider/a_21/i5.jpg")},
            {source : require("../img/property_slider/a_21/i6.jpg")},
            {source : require("../img/property_slider/a_21/i7.jpg")},
            {source : require("../img/property_slider/a_21/i8.jpg")},
        ],
        "d_31_1" : [
            {source : require("../img/property_slider/d_31_1/i1.jpg")},
            {source : require("../img/property_slider/d_31_1/i2.jpg")},
            {source : require("../img/property_slider/d_31_1/i3.jpg")},
            {source : require("../img/property_slider/d_31_1/i4.jpg")},
            {source : require("../img/property_slider/d_31_1/i5.jpg")},
            {source : require("../img/property_slider/d_31_1/i6.jpg")},
            {source : require("../img/property_slider/d_31_1/i7.jpg")},
            {source : require("../img/property_slider/d_31_1/i8.jpg")},
            {source : require("../img/property_slider/d_31_1/i9.jpg")},
            {source : require("../img/property_slider/d_31_1/i10.jpg")},
            {source : require("../img/property_slider/d_31_1/i11.jpg")},
        ],
        "d_31_2" : [
            {source : require("../img/property_slider/d_31_2/i1.jpg")},
            {source : require("../img/property_slider/d_31_2/i2.jpg")},
            {source : require("../img/property_slider/d_31_2/i3.jpg")},
            {source : require("../img/property_slider/d_31_2/i4.jpg")},
            {source : require("../img/property_slider/d_31_2/i5.jpg")},
            {source : require("../img/property_slider/d_31_2/i6.jpg")},
            {source : require("../img/property_slider/d_31_2/i7.jpg")},
            {source : require("../img/property_slider/d_31_2/i8.jpg")},
            {source : require("../img/property_slider/d_31_2/i9.jpg")},
            {source : require("../img/property_slider/d_31_2/i10.jpg")},
            {source : require("../img/property_slider/d_31_2/i11.jpg")},
        ],
        "e_11" : [
            {source : require("../img/property_slider/e_11/i1.jpg")},
            {source : require("../img/property_slider/e_11/i2.jpg")},
            {source : require("../img/property_slider/e_11/i3.jpg")},
            {source : require("../img/property_slider/e_11/i4.jpg")},
            {source : require("../img/property_slider/e_11/i5.jpg")},
            {source : require("../img/property_slider/e_11/i6.jpg")},
        ],
        "f_31" : [
            {source : require("../img/property_slider/f_31/i1.jpg")},
            {source : require("../img/property_slider/f_31/i2.jpg")},
            {source : require("../img/property_slider/f_31/i3.jpg")},
            {source : require("../img/property_slider/f_31/i4.jpg")},
            {source : require("../img/property_slider/f_31/i5.jpg")},
            {source : require("../img/property_slider/f_31/i6.jpg")},
            {source : require("../img/property_slider/f_31/i7.jpg")},
            {source : require("../img/property_slider/f_31/i8.jpg")},
            {source : require("../img/property_slider/f_31/i9.jpg")},
            {source : require("../img/property_slider/f_31/i10.jpg")},
            {source : require("../img/property_slider/f_31/i11.jpg")},
            {source : require("../img/property_slider/f_31/i12.jpg")},
        ],
        "g_41" : [
            {source : require("../img/property_slider/g_41/i1.jpg")},
            {source : require("../img/property_slider/g_41/i2.jpg")},
            {source : require("../img/property_slider/g_41/i3.jpg")},
            {source : require("../img/property_slider/g_41/i4.jpg")},
            {source : require("../img/property_slider/g_41/i5.jpg")},
            {source : require("../img/property_slider/g_41/i6.jpg")},
            {source : require("../img/property_slider/g_41/i7.jpg")},
            {source : require("../img/property_slider/g_41/i8.jpg")},
            {source : require("../img/property_slider/g_41/i9.jpg")},
            {source : require("../img/property_slider/g_41/i10.jpg")},
            {source : require("../img/property_slider/g_41/i11.jpg")},
            {source : require("../img/property_slider/g_41/i12.jpg")},
            {source : require("../img/property_slider/g_41/i13.jpg")},
            {source : require("../img/property_slider/g_41/i14.jpg")},
        ],
    },
    thumb : {
        "a_11" : [
            {source : require("../img/property_thumbs/a_11/i1.jpg")},
        ],
        "a_21" : [
            {source : require("../img/property_thumbs/a_21/i1.jpg")},
        ],
        "d_31_1" : [
            {source : require("../img/property_thumbs/d_31_1/i1.jpg")},
        ],
        "d_31_2" : [
            {source : require("../img/property_thumbs/d_31_2/i1.jpg")},
        ],
        "e_11" : [
            {source : require("../img/property_thumbs/e_11/i1.jpg")},
        ],
        "f_31" : [
            {source : require("../img/property_thumbs/f_31/i1.jpg")},
        ],
        "g_41" : [
            {source : require("../img/property_thumbs/g_41/i1.jpg")},
        ],

    },
    floorplan_slider : {
        "a_11" : [
            {source : require("../img/property_floorplan/a_11/i1.jpg")},
        ],
        "a_21" : [
            {source : require("../img/property_floorplan/a_21/i1.jpg")},

        ],
        "d_31_1" : [
            {source : require("../img/property_floorplan/d_31_1/i1.jpg")},

        ],
        "d_31_2" : [
            {source : require("../img/property_floorplan/d_31_2/i1.jpg")},

        ],
        "e_11" : [
            {source : require("../img/property_floorplan/e_11/i1.jpg")},

        ],
        "f_31" : [
            {source : require("../img/property_floorplan/f_31/i1.jpg")},
            {source : require("../img/property_floorplan/f_31/i2.jpg")},

        ],
        "g_41" : [
            {source : require("../img/property_floorplan/g_41/i1.jpg")},
            {source : require("../img/property_floorplan/g_41/i2.jpg")},

        ],
    },

    vr360 : {
        "a_11" : "https://cy.kd.mediterra2.wizio.co.uk/360/a_11/",
        "a_21" : "https://cy.kd.mediterra2.wizio.co.uk/360/a_21/",
        "d_31_1" : "https://cy.kd.mediterra2.wizio.co.uk/360/d_31_1/",
        "d_31_2" : "https://cy.kd.mediterra2.wizio.co.uk/360/d_31_2/",
        "e_11" : "https://cy.kd.mediterra2.wizio.co.uk/360/e_11/",
        "f_31" : "https://cy.kd.mediterra2.wizio.co.uk/360/f_31/",
        "g_41" : "https://cy.kd.mediterra2.wizio.co.uk/360/g_41/",
    }
}