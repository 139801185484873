import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/Dashboard.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import UserContext from "../context/UserContext";

function DashboardManagerHome() {
  const {user,setUser} = useContext(UserContext);
  const [chartType, setChartType] = useState(0);

  const [data2,setData2] = useState(null);
  const [data3,setData3] = useState(null);
  const [totals,setTotals] = useState({presentation : -1, client : -1, option : -1, sold : -1})
  const [dataMonthly,setDataMonthly] = useState(null);
  const [dataYearly,setDataYearly] = useState(null);


  useEffect(() => {
    apiConst.getUser(user.id).then(user => {


      let data_3 = {
        className: "recent-views",
        title: "Recent Views",
        headings: ["Date", "Sales Rep", "Client", "Property"],
        rows: []
      };

      user.manager.views.forEach(view => {
        data_3.rows.push({date: view.date,salesRep: {id: view.user_id, name: view.user_name}, client: {id: view.client_id, name :view.client_name}, property: {id:view.property_id, name :view.property_code}})
      })
      setData3(data_3);

      let data_2 = {
        className: "recent-presentations-with-sales-rep",
        title: "Recent Presentations",
        headings: ["Date","Sales Rep", "Client"],
        rows: []
      };
      user.manager.presentations.forEach(presentation => {
        data_2.rows.push({date: presentation.date,salesRep: {id: presentation.user_id, name: presentation.user_name}, client: {id: presentation.client_id, name :presentation.client_name}})
      })
      setData2(data_2);

      setTotals({
        presentation : user.totals.presentation,
        client : user.totals.client,
        option : user.totals.option,
        sold : user.totals.sold,
      });


    })

    apiConst.getActionsByMonthAndYear(user.id).then(data => {
      let chartMonthlyData = [];
      data.option.monthly.forEach(month => {
        chartMonthlyData.push({name:month[0],option:month[1]})
      })
      data.sold.monthly.forEach((month,i) => {
        chartMonthlyData[i] = {...chartMonthlyData[i],sold:month[1]}
      })
      setDataMonthly(chartMonthlyData);

      let chartYearlyData = [];
      data.option.yearly.forEach(year => {
        chartYearlyData.push({name:year[0],option:year[1]})
      })
      data.sold.yearly.forEach((year,i) => {
        chartYearlyData[i] = {...chartYearlyData[i],sold:year[1]}
      })
      setDataYearly(chartYearlyData);
    });


  },[])

  return (

    <section className="primary dashboard home manager">
      
      <DashboardHeader slug="home" />
  
      <div className="container">
        
        <div className="row">
          
          <div className="col-2">

            <NumberBox className="number-box" number={totals.presentation} text="Presentation" />
            <NumberBox className="number-box" number={totals.client} text="Client" />
            <NumberBox className="number-box" number={totals.option} text="Option" delay="200" />
            <NumberBox className="number-box" number={totals.sold} text="Sold" />

          </div>


          <div className="col-10">

            <div className="row">
              <div className="col-12">

                <div className="chart-container">

                  <div className="header">
                    <h3>Property Actions</h3>
                    
                    <select className="form-control" onChange={(e)=>{
                      
                      setChartType(parseInt(e.target.value));
                      
                    }}>
                      <option value="0">Monthly</option>
                      <option value="1">Yearly</option>
                    </select>

                  </div>


                  {chartType === 0 && (
                    <ResponsiveContainer width="100%" height="80%">
                      <LineChart data={dataMonthly}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        
                        <Line dataKey="option" stroke={getComputedStyle(document.documentElement).getPropertyValue('--chart-first-color').trim()} strokeWidth="2" dot={{ r: 6 }} />
                        <Line dataKey="sold" stroke="rgba(10, 31, 59, 0.4)" strokeWidth="2" dot={{ r: 6 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  )}

                  {chartType === 1 && (
                    <ResponsiveContainer width="100%" height="80%">
                      <LineChart data={dataYearly}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        
                        <Line dataKey="option" stroke={getComputedStyle(document.documentElement).getPropertyValue('--chart-first-color').trim()} strokeWidth="2" dot={{ r: 6 }} />
                        <Line dataKey="sold" stroke="rgba(10, 31, 59, 0.4)" strokeWidth="2" dot={{ r: 6 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  )}

                  
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <DashboardTable data={data2} />
              </div>

              <div className="col-6">
                <DashboardTable data={data3} />
              </div>
            </div>
            
         </div>
          
        </div>
      </div>
    </section>

  );
  
}

export default DashboardManagerHome;