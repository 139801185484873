import React, {useContext, useEffect} from 'react';
import "../css/FavCompareCard.css";
import {propertyConst} from "../constant/propertyConst";
import {appSettings} from "../constant/appConst";
import {appUtil} from "../constant/appUtil";
import FavCompareContext from "./context/FavCompareContext";
import {Link} from "react-router-dom";
function FavCompareCard({fav}) {
    const {favCompareList,setFavCompareList} = useContext(FavCompareContext);

    function getImage(){
        let thumbs = propertyConst.thumb[fav.property.room_type_folder];
        return (thumbs[0].source);
    }

    function onClickClose(){

        let newFavCompareList = [...favCompareList];
        favCompareList.forEach((item,i) => {
            if(item === fav) {
                newFavCompareList.splice(i,1)
            }
        })
        setFavCompareList(newFavCompareList);

    }

    return (
        <div className={"compare-list-item"} >
            <button className="close-button colored" onClick={onClickClose}>
                <div></div>
                <div></div>
            </button>
            <div className="compare-list-item-img">
                <img className="img-fluid" src={getImage()} alt=""/>
            </div>
            <div className="compare-list-item-text">
                <div>
                    <span>CODE</span>
                    <span>{fav.property.code}</span>
                </div>
                <div>
                    <span>PRICE</span>
                    <span>{appUtil.formatPrice(fav.property.price)}</span>
                </div>
                <div>
                    <span>FLOOR TYPE</span>
                    <span>{fav.property.room_type.label}</span>
                </div>
                <div>
                    <span>UNIT AREA</span>
                    <span>{fav.property.net_area} {appSettings.areaUnit}</span>
                </div>
                <div>
                    <span>GARDEN AREA</span>
                    <span>{fav.property.garden_area} {appSettings.areaUnit}</span>
                </div>
                <div>
                    <span>TERRACE AREA</span>
                    <span>{fav.property.terrace_area} {appSettings.areaUnit}</span>
                </div>
                <div>
                    <span>BATHROOMS</span>
                    <span>{fav.property.bathrooms}</span>
                </div>
                <div>
                    <span>PARKING</span>
                    <span>{(fav.property.code.substring(0,1) === "F" || fav.property.code.substring(0,1) === "G") ? 2 : 1}</span>
                </div>
            </div>
            <div className="compare-list-item-button">
                <Link to={"/property-view/" + fav.property.id} className={"btn-review-details"} ><span className="icon"></span>REVIEW IN DETAILS</Link>
            </div>

        </div>
    );
}

export default FavCompareCard;