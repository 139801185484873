import ProjectMarker from "../components/ProjectMarker";
import GoogleMap from "google-map-react";
import React from "react";

const appSettings = {
    projectName: "Mediterra",
    currencyUnit: "£",
    currencyUnitPosition: "before",
    areaUnit: <>m<sup>2</sup></>,
    showPrices: true,
    priceFilter: {
        unit: 10000,
        min: 230000,
        max: 1250000,
    },
    showIntroVideo : false,
    is360External : true,
    clientVerification : false,
    showRegisterClientPage : true,
    botSalesRep : "developer@wizio.co.uk"
};

const appConst = {
    
    menu: {
        gallery : [
            {id:1, title:'Exterior',isExternal : false,link: '/gallery/exterior'},
            {id:2, title:'Interior',isExternal : false,link: '/gallery/interior'},
            {id:3, title:'Social Areas',isExternal : false,link: '/gallery/social-areas'},
            {id:4, title:'Social Area Tour',isExternal : appSettings.is360External, link: appSettings.is360External ? 'https://cy.kd.mediterra2.wizio.co.uk/360/social' : '/gallery/social-area-tour'},

        ],
        location_category : [
            {id : "shopping",title : "Shopping", zoom : 10},
            {id : "social",title : "Social", zoom : 15},
            {id : "blueway",title : "Blueway", zoom : 15},
            {id : "health",title : "Health", zoom : 15},
            {id : "banking",title : "Banking", zoom : 15},
            {id : "public_place",title : "Public", zoom : 15},
            {id : "transportation",title : "Transportation", zoom : 15},
            {id : "schools",title : "Schools", zoom : 15},
        ],
    location : {
        shopping : [
                {id:1, title:'Elysee Süpermarket', link: '/location/shopping/1', lat:35.3460225233123, lng:33.23026362169785},
                {id:2, title:'Ileli Plus', link: '/location/shopping/2', lat:35.34707924097133, lng:33.201476116002965, },
                {id:3, title:'Ileli Süpermarket', link: '/location/shopping/3', lat:35.34837303996844, lng:33.22242699142092, },
                {id:4, title:'Starling Market', link: '/location/shopping/4', lat:35.34862051226424, lng:33.2213655451526},
                {id:5, title:'Bestmar SüperMarket 2', link: '/location/shopping/5', lat:35.347742314684325, lng:33.210947760083215},
                {id:6, title:'Mediterranean Home & Garden', link: '/location/shopping/6', lat:35.349384722861245, lng:33.21867189379066},
                {id:7, title:'Yeşiltepe Petrol Ltd.', link: '/location/shopping/7', lat:35.34791246736305, lng:33.224758924536886},
                {id:8, title:'K-Pet Atakara Benzin Istasyonu', link: '/location/shopping/8', lat:35.34637918759346, lng:33.20622353086316}
            ],

        social :  [
                {id:9, title:'The Heaven Restoran & Cafe', link: '/location/social/9', lat:35.34291763732886, lng:33.24222167561776},
                {id:10, title:'Saint Tropez Restaurant', link: '/location/social/10', lat:35.347277947892124, lng:33.22721384980729},
                {id:11, title:'Sevener Restaurant', link: '/location/social/11', lat:35.34705832756002, lng:33.2086831429747},
                {id:12, title:'The Taj Indian Restaurant & Bar', link: '/location/social/12', lat:35.347388991587735, lng:33.20522585200439,},
                {id:13, title:'Azra Claire’s Asian Alsancak', link: '/location/social/13', lat:35.34710249940167, lng:33.20176433415275,},
                {id:14, title:'Suna\'s Beach Club', link: '/location/social/14', lat:35.349936629720915, lng:33.17963168413816},
                {id:15, title:'Chill Beach Bar & Restaurant', link: '/location/social/15', lat:35.350811578434545, lng:33.17318748143762},
                {id:16, title:'Kervan Restaurant & Cafe', link: '/location/social/16', lat:35.34777095361887, lng:33.25620965382927},
                {id:17, title:'The Ambiance Restoran', link: '/location/social/17', lat:35.34732888173119, lng:33.26662069442696},
                {id:18, title:'Silver Rocks Restaurant', link: '/location/social/18', lat:35.35200635057571, lng:33.16428062883607},
                {id:19, title:'Sea Point Restaurant', link: '/location/social/19', lat:35.350698153672646, lng:33.17551370274647},
                {id:20, title:'Archway Restaurant', link: '/location/social/20', lat:35.33037612052786, lng:33.2962385909849},
                {id:21, title:'Eziç Premier', link: '/location/social/21', lat:35.32674279416179, lng:33.3083637705523},
                {id:22, title:'Calamari Restaurant', link: '/location/social/22', lat:35.350716332833414,lng: 33.17597864493878},
                {id:23, title:'Babil Bahçeleri', link: '/location/social/23', lat:35.3435114061934, lng:33.180498575826064},
            ],

        blueway : [
            {id:24, title:'Gave', link: '/location/blueway/24', lat:35.3436795671342, lng:33.23055206323002},
            {id:25, title:'Natura SPA & Wellness', link: '/location/blueway/25', lat:35.343377953173956,lng: 33.23229545916726},
            {id:26, title:'Terra', link: '/location/blueway/26', lat:35.3373170742178,lng: 33.22690146856415},
            {id:27, title:'Kibris Developments', link: '/location/blueway/27', lat:35.342640439367955,lng: 33.24763740874663},
            {id:28, title:'Milos Park Homes', link: '/location/blueway/28', lat:35.33878272866901, lng:33.20121049847417},
            {id:29, title:'Kibris Town Houses', link: '/location/blueway/29', lat:35.342939584764835,lng: 33.23002590306811},
            {id:30, title:'Natura Appartments', link: '/location/blueway/30', lat:35.34425919269946, lng:33.23133529262996},
        ],

        health : [
            {id:31, title:'Emin Eczanesi', link: '/location/health/31', lat:35.34932502166563, lng:33.22065478591201},
            {id:32, title:'Asrın Eczanesi', link: '/location/health/32', lat:35.34800341921136, lng:33.22444904918698},
            {id:33, title:'Pınar Keklik Eczanesi', link: '/location/health/33', lat:35.34758843347161, lng:33.209942341100536},
            {id:34, title:'DuoLab Tıbbi Tahlil Laboratuvarı', link: '/location/health/34', lat:35.347143543185986,lng: 33.203409475346376},
            {id:35, title:'İpek Yöney Eczanesi', link: '/location/health/35', lat:35.34418200326344, lng:33.2565404747478},
            {id:36, title:'Nazım Varış Eczanesi', link: '/location/health/36', lat:35.34715503692521, lng:33.203500515501716},
            {id:37, title:'Varış Tıp Merkezi', link: '/location/health/37', lat:35.34710164694424, lng:33.20332965587183},
        ],

        banking : [
            {id:38, title:'Türkiye İş Bankası Bankamatik', link: '/location/banking/38', lat:35.3488231822502, lng:33.221227882658305},
            {id:39, title:'Garanti Paramatik', link: '/location/banking/39', lat:35.347928708166364,lng: 33.224638554002034},
            {id:40, title:'TEB Atm', link: '/location/banking/40', lat:35.34863675616704, lng:33.22147840589467},
            {id:41, title:'Creditwest Bank Alsancak Şubesi', link: '/location/banking/41', lat:35.34218010416178, lng:33.24603226553475},
            {id:42, title:'Türk Bankası ATM', link: '/location/banking/42', lat:35.347362115307206,lng: 33.20729048535028},
            {id:43, title:'Cardplus ATM', link: '/location/banking/43', lat:35.34484538720077, lng:33.20218443689466},
        ],

        public_place : [
            {id:44, title:'Ç1974 Gemi Müzesi', link: '/location/public_place/44', lat:35.34488160492654, lng:33.236867817924065},
            {id:45, title:'Barış ve Özgürlük Müzesi', link: '/location/public_place/45', lat:35.344289572662944,lng: 33.24159219509855},
            {id:46, title:'Karaoğlanoğlu Müzesi', link: '/location/public_place/46', lat:35.34407551043806, lng:33.24244395898565},
            {id:47, title:'Açık Hava Araç Müzesi', link: '/location/public_place/47', lat:35.344053749329156,lng: 33.2415145893811},
            {id:48, title:'Alsancak Municipality', link: '/location/public_place/48', lat:35.34419915480734, lng:33.196631965947276},
            {id:49, title:'Alsancak National Nature Park', link: '/location/public_place/49', lat:35.342732926674586,lng: 33.23855303722728},
            {id:50, title:'Lambouse Kenti Balık Havuzları', link: '/location/public_place/50', lat:35.35822557250403, lng:33.19808388872003},
        ],
        transportation : [
            {id:51, title:'Ercan Havalimanı Taksi', link: '/location/transportation/51', lat:35.158231234373474,lng: 33.50164217476468},
            {id:52, title:'Larnaca International Airport', link: '/location/transportation/52', lat:34.872321577562204,lng: 33.62031152033011},
            {id:53, title:'Paphos International Airport', link: '/location/transportation/53', lat:34.71692651629665, lng:32.48431468836064},
        ],
        schools : [
            {id:54, title:'The American College', link: '/location/schools/54', lat:35.331968764838585,lng: 33.26989415822631},
            {id:55, title:'Bambini Montessori Nursery', link: '/location/schools/55', lat:35.3386877263257, lng:33.259288639174706},
            {id:56, title:'Necat British College', link: '/location/schools/56', lat:35.33714231124869, lng:33.205558859891255},
            {id:57, title:'The English School of Kyrenia', link: '/location/schools/57', lat:35.316422400009756,lng: 33.34335396044983},
        ]

    }

    },
    gallery : {

        exterior : [
            {caption:'Exterior 1', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:'Exterior 2', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:'Exterior 3', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:'Exterior 4', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:'Exterior 5', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:'Exterior 6', source: require("../img/gallery/exterior/i6.jpg")},
            {caption:'Exterior 7', source: require("../img/gallery/exterior/i7.jpg")},
            {caption:'Exterior 8', source: require("../img/gallery/exterior/i8.jpg")},
            {caption:'Exterior 9', source: require("../img/gallery/exterior/i9.jpg")},


        ],
        interior : [
            {caption:'Interior 1', source: require("../img/gallery/interior/i1.jpg")},
            {caption:'Interior 2', source: require("../img/gallery/interior/i2.jpg")},
            {caption:'Interior 3', source: require("../img/gallery/interior/i3.jpg")},
            {caption:'Interior 4', source: require("../img/gallery/interior/i4.jpg")},
            {caption:'Interior 5', source: require("../img/gallery/interior/i5.jpg")},
            {caption:'Interior 6', source: require("../img/gallery/interior/i6.jpg")},
            {caption:'Interior 7', source: require("../img/gallery/interior/i7.jpg")},
            {caption:'Interior 8', source: require("../img/gallery/interior/i8.jpg")},
            {caption:'Interior 9', source: require("../img/gallery/interior/i9.jpg")},
            {caption:'Interior 10', source: require("../img/gallery/interior/i10.jpg")},
            {caption:'Interior 11', source: require("../img/gallery/interior/i11.jpg")},

        ],

        social : [
            {caption:'Social Area 1', source: require("../img/gallery/social/i1.jpg")},
            {caption:'Social Area 2', source: require("../img/gallery/social/i2.jpg")},
            {caption:'Social Area 3', source: require("../img/gallery/social/i3.jpg")},
            {caption:'Social Area 4', source: require("../img/gallery/social/i4.jpg")},
            {caption:'Social Area 5', source: require("../img/gallery/social/i5.jpg")},
            {caption:'Social Area 6', source: require("../img/gallery/social/i6.jpg")},
            {caption:'Social Area 7', source: require("../img/gallery/social/i7.jpg")},
            {caption:'Social Area 8', source: require("../img/gallery/social/i8.jpg")},
            {caption:'Social Area 9', source: require("../img/gallery/social/i9.jpg")},
            {caption:'Social Area 10', source: require("../img/gallery/social/i10.jpg")},


        ],

    },
    brochure : [

    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        not_released : 4
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Not Released";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};

export {
    appSettings,
    appConst,
};